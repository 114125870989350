import {useState} from 'react'
import {Disclosure} from '@headlessui/react'
import {BellIcon} from '@heroicons/react/24/outline'
import {Link} from "react-router-dom";
import {LOGO_SRC} from "../constants.ts";


export default function Navbar() {
    const [activeLink, setActiveLink] = useState('Home');
    const links = [
        {name: 'Browse Date Night Ideas', endpoint: '/'},
        {name: 'Blog', endpoint: '/blog'},
        // {name: 'About', endpoint: '/about'},
    ];

    return (
        <Disclosure as="nav" className="bg-white shadow">
            <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex h-16 justify-between">
                        <div className="flex">
                            <div className="flex flex-shrink-0 items-center">
                                <Link to="/">
                                    <img
                                        className="h-10 w-auto"
                                        src={LOGO_SRC}
                                        alt="AIDateNightPlanner Logo"
                                    />
                                </Link>
                            </div>
                            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                {/* Current: "border-red-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                {links.map((link) => (
                                    <Link
                                        to={link.endpoint}
                                        onClick={() => setActiveLink(link.name)}
                                        key={link.name}
                                        className={`inline-flex items-center px-1 pt-1 text-sm font-medium ${
                                            activeLink === link.name
                                                ? 'border-b-2 border-red-500 text-gray-900'
                                                : 'border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                                        }`}
                                    >
                                        {link.name}
                                    </Link>
                                ))}
                            </div>
                        </div>
                        <div className="flex items-center">
                            {/*<div className="flex-shrink-0">*/}
                            {/*    <Link*/}
                            {/*        to={"/upload"}*/}
                            {/*        className="relative inline-flex items-center gap-x-2.5 rounded-md bg-red-600 mr-2 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"*/}
                            {/*    >*/}
                            {/*        <HeartIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>*/}
                            {/*        Get Personalized Date Night Ideas*/}
                            {/*    </Link>*/}
                            {/*</div>*/}
                            {/*<button*/}
                            {/*    type="button"*/}
                            {/*    className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"*/}
                            {/*>*/}
                            {/*    <span className="absolute -inset-1.5"/>*/}
                            {/*    <span className="sr-only">View notifications</span>*/}
                            {/*    <BellIcon className="h-6 w-6" aria-hidden="true"/>*/}
                            {/*</button>*/}

                            {/*/!* Profile dropdown *!/*/}
                            {/*<Menu as="div" className="relative ml-3">*/}
                            {/*    <div>*/}
                            {/*        <Menu.Button*/}
                            {/*            className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">*/}
                            {/*            <span className="absolute -inset-1.5"/>*/}
                            {/*            <span className="sr-only">Open user menu</span>*/}
                            {/*            <img*/}
                            {/*                className="h-8 w-8 rounded-full"*/}
                            {/*                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
                            {/*                alt=""*/}
                            {/*            />*/}
                            {/*        </Menu.Button>*/}
                            {/*    </div>*/}
                            {/*    <Transition*/}
                            {/*        as={Fragment}*/}
                            {/*        enter="transition ease-out duration-200"*/}
                            {/*        enterFrom="transform opacity-0 scale-95"*/}
                            {/*        enterTo="transform opacity-100 scale-100"*/}
                            {/*        leave="transition ease-in duration-75"*/}
                            {/*        leaveFrom="transform opacity-100 scale-100"*/}
                            {/*        leaveTo="transform opacity-0 scale-95"*/}
                            {/*    >*/}
                            {/*        <Menu.Items*/}
                            {/*            className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">*/}
                            {/*            <Menu.Item>*/}
                            {/*                {({active}) => (*/}
                            {/*                    <a*/}
                            {/*                        href="#"*/}
                            {/*                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}*/}
                            {/*                    >*/}
                            {/*                        Your Profile*/}
                            {/*                    </a>*/}
                            {/*                )}*/}
                            {/*            </Menu.Item>*/}
                            {/*            <Menu.Item>*/}
                            {/*                {({active}) => (*/}
                            {/*                    <a*/}
                            {/*                        href="#"*/}
                            {/*                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}*/}
                            {/*                    >*/}
                            {/*                        Settings*/}
                            {/*                    </a>*/}
                            {/*                )}*/}
                            {/*            </Menu.Item>*/}
                            {/*            <Menu.Item>*/}
                            {/*                {({active}) => (*/}
                            {/*                    <a*/}
                            {/*                        href="#"*/}
                            {/*                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}*/}
                            {/*                    >*/}
                            {/*                        Sign out*/}
                            {/*                    </a>*/}
                            {/*                )}*/}
                            {/*            </Menu.Item>*/}
                            {/*        </Menu.Items>*/}
                            {/*    </Transition>*/}
                            {/*</Menu>*/}
                        </div>
                        {/*<div className="-mr-2 flex items-center sm:hidden">*/}
                        {/*    /!* Mobile menu button *!/*/}
                        {/*    <Disclosure.Button*/}
                        {/*        className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">*/}
                        {/*        <span className="absolute -inset-0.5"/>*/}
                        {/*        <span className="sr-only">Open main menu</span>*/}
                        {/*        {open ? (*/}
                        {/*            <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>*/}
                        {/*        ) : (*/}
                        {/*            <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>*/}
                        {/*        )}*/}
                        {/*    </Disclosure.Button>*/}
                        {/*</div>*/}
                    </div>
                </div>

                <Disclosure.Panel className="sm:hidden">
                    <div className="space-y-1 pb-3 pt-2">
                        {links.map((link) => (
                            <Disclosure.Button
                                as={Link}
                                to={link.endpoint}
                                key={link.name}
                                onClick={() => setActiveLink(link.name)}
                                className={`block py-2 pl-3 pr-4 text-base font-medium ${
                                    activeLink === link.name
                                        ? 'border-l-4 border-red-500 bg-red-50 text-red-700'
                                        : 'border-l-4 border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'
                                }`}
                            >
                                {link.name}
                            </Disclosure.Button>
                        ))}
                    </div>
                    <div className="border-t border-gray-200 pb-3 pt-4">
                        <div className="flex items-center px-4">
                            <div className="flex-shrink-0">
                                <img
                                    className="h-10 w-10 rounded-full"
                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    alt=""
                                />
                            </div>
                            <div className="ml-3">
                                <div className="text-base font-medium text-gray-800">Tom Cook</div>
                                <div className="text-sm font-medium text-gray-500">tom@example.com</div>
                            </div>
                            <button
                                type="button"
                                className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            >
                                <span className="absolute -inset-1.5"/>
                                <span className="sr-only">View notifications</span>
                                <BellIcon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="mt-3 space-y-1">
                            <Disclosure.Button
                                as="a"
                                href="#"
                                className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                            >
                                Your Profile
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="#"
                                className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                            >
                                Settings
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="#"
                                className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                            >
                                Sign out
                            </Disclosure.Button>
                        </div>
                    </div>
                </Disclosure.Panel>
            </>
        </Disclosure>
    )
}
