// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const API_URL = import.meta.env.VITE_BACKEND_API_URL;


export const GET_DATE_IDEAS_IDS = (page: string, size: string, sort: string, filters: string) => {
    const url = new URL(`${API_URL}/date-ideas/`);
    url.searchParams.append('page', page);
    url.searchParams.append('size', size);
    url.searchParams.append('sort', sort);
    url.searchParams.append('filters', filters);  // Encode the filters as a JSON string
    return url;
}

export const GET_DATE_IDEA_BY_ID = (idea_id: number) => {
    return new URL(`${API_URL}/date-ideas/${idea_id}`);
}

export const GET_ACTIVITIES = (page: number, size: number) => {
    return `${API_URL}/activities/?page=${page}&size=${size}/`;
}

export const GET_LOCATIONS = (page: number, size: number) => {
    return `${API_URL}/locations/?page=${page}&size=${size}/`;
}
export const TRACK_CLICK = (idea_id: number) => {
    return `${API_URL}/date-ideas/${idea_id}/click`;
}


// blog
export const GET_BLOG_HOMEPAGE = () => {
    return `${API_URL}/blog`;
}

export const GET_BLOG_POST = (slug: string) => {
    return `${API_URL}/blog_post/${slug}`;
}

export const GET_BLOG_CATEGORIES = (slug: string) => {
    return `${API_URL}/blog/category/${slug}`;
}

export const GET_BLOG_ARCHIVE = (year: number, month: number) => {
    return `${API_URL}/blog/archive/${year}/${month}`;
}