import {useEffect, useState} from "react";
import {GET_BLOG_ARCHIVE} from "../endpoints.tsx";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";


const BlogArchives = () => {
    const {year, month} = useParams();

    const [archives, setArchives] = useState<Archive[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [articles, setArticles] = useState<FeaturedPost[]>([]);

    const formatDate = (dateString: string) => {
        const options = {year: 'numeric', month: 'long', day: 'numeric'};
        // @ts-ignore
        return new Date(dateString).toLocaleDateString(undefined, options);
    }
    useEffect(() => {
        const fetchData = () => {
            // @ts-ignore
            fetch(GET_BLOG_ARCHIVE(year, month))
                .then(response => response.json())
                .then(data => {
                    setArchives(data.archives);
                    setCategories(data.categories);
                    setArticles(data.articles);
                })
                .catch(error => console.error('Error:', error));
        };

        fetchData();
    }, []);

    useEffect(() => {
    }, [articles]);

    return (
        <div className="bg-white pb-24 sm:pb-32 pt-12">
            <Helmet>
                <title>{`${year}-${month} Archives - AIDateNightPlanner`}</title>
                <meta name="description"
                      content={`Explore articles from the ${month} ${year} archives on AIDateNightPlanner. Discover tips and advice on dating and relationships from our past articles.`}/>
            </Helmet>
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{year}-{month} archives</h2>
                <p className="mt-2 text-lg leading-8 text-gray-600">
                    Discover the date night archives that suits you best.
                </p>
            </div>
            <div className="mx-auto max-w-7xl px-6 lg:px-8 flex flex-col sm:flex-row pt-12">
                <div className="mx-auto w-full sm:w-3/4">
                    <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                        {articles.map((article) => (
                            <article key={article.id}
                                     className="relative isolate flex flex-col gap-8 lg:flex-row my-12">
                                <div
                                    className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
                                    <img
                                        src={article.img_src_large}
                                        alt=""
                                        className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                                    />
                                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"/>
                                </div>
                                <div>
                                    <div className="flex items-center gap-x-4 text-xs">
                                        <time dateTime={article.date_posted} className="text-gray-500">
                                            {formatDate(article.date_posted)}
                                        </time>
                                        <a
                                            href={`/blog/category/${article.category}`}
                                            className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                                        >
                                            {article.category}
                                        </a>
                                    </div>
                                    <div className="group relative max-w-xl">
                                        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                            <a href={`/blog_post/${article.slug}`}>
                                                <span className="absolute inset-0"/>
                                                {article.title}
                                            </a>
                                        </h3>
                                        <p className="mt-5 text-sm leading-6 text-gray-600">{article.meta_description}</p>
                                    </div>

                                </div>
                            </article>
                        ))}
                    </div>
                </div>
                <div className="w-full sm:w-1/4 mt-6 sm:mt-0 ">

                    <div className="card bg-white p-6 rounded-lg shadow prose">
                        <h2 className="text-2xl font-bold mb-2">About</h2>
                        <p>AIdateNightPlanner is a platform that provides advice on dating and relationships. We
                            offer a variety of articles and resources to help you plan the perfect date night. </p>
                    </div>

                    <div className="card bg-white p-6 rounded-lg shadow mt-6">
                        <h2 className="text-2xl font-bold mb-2">Categories</h2>
                        <ul className={"prose"}>
                            {categories.map((category) => (
                                <li key={category}><a href={`/blog/category/${category}`}>{category}</a></li>
                            ))}
                        </ul>
                    </div>

                    <div className="card bg-white p-6 rounded-lg shadow mt-6">
                        <h2 className="text-2xl font-bold mb-2">Archives</h2>
                        <ul className={"prose"}>
                            {archives.map((archive) => (
                                <li key={`${archive.year}-${archive.month}`}><a
                                    href={`/blog/archive/${archive.year}/${archive.month}`}>{archive.text}</a></li>
                            ))}
                        </ul>
                    </div>
                </div>

            </div>

        </div>


    )
}

export default BlogArchives;