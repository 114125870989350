import {Fragment, useEffect, useMemo, useRef, useState} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {ChevronDownIcon, FunnelIcon} from '@heroicons/react/20/solid'
import {GET_DATE_IDEAS_IDS} from "../endpoints.tsx";
import DateIdea from "../components/DateIdea.tsx";
import {useLocation, useNavigate} from "react-router-dom";
import {filters, sortOptions} from "../constants.ts";


function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

type DateIdeaId = {
    id: number;
};

type DateIdeaIds = DateIdeaId[];
export default function HomePage() {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const [triggerFetch, setTriggerFetch] = useState(false);


    const initialPage = parseInt(searchParams.get('page') || '1', 10);
    const initialSort = searchParams.get('sort') || sortOptions[0].sortParam;
    const initialPageSize = parseInt(searchParams.get('pageSize') || '25', 10);

    const initialFilters = useMemo(() => {
        const filterParams = searchParams.get('filters');
        const parsedFilters = filterParams ? JSON.parse(filterParams) : {};

        // Iterate through all filters and update the `checked` status based on the URL
        return Object.entries(filters).reduce((acc, [key, options]) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            acc[key] = options.map(option => ({
                ...option,
                checked: parsedFilters[key] ? parsedFilters[key].includes(option.value) : false
            }));
            return acc;
        }, {});
    }, [location.search]);

    const [dateIdeasIds, setDateIdeasIds] = useState<DateIdeaIds>([]);
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [pageSize] = useState(initialPageSize);
    const [currentSort, setCurrentSort] = useState(initialSort);
    const [currentFilters, setCurrentFilters] = useState(initialFilters);
    const [stagedFilters, setStagedFilters] = useState(currentFilters);

    const loadMoreRef = useRef(null);


    useEffect(() => {
        // Encode state to URL

        const params = new URLSearchParams({
            sort: currentSort,
            pageSize: pageSize.toString(),
            // Include only filters that are checked
            filters: JSON.stringify(Object.fromEntries(Object.entries(currentFilters).map(([key, value]) => [
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                key, value.filter((f: { checked: never; }) => f.checked).map((f: { value: never; }) => f.value)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ]).filter(([_, value]) => value.length > 0)))

        });
        navigate({search: params.toString()});
    }, [currentSort, pageSize, currentFilters, navigate]);

    useEffect(() => {
        // Reset page number and clear existing date ideas
        setCurrentPage(1);
        setDateIdeasIds([]);

        // We do not call the API here as the below effect will run immediately after this one
    }, [currentSort, currentFilters]);


    useEffect(() => {
        // Optionally check if filters are non-default before fetching
        const hasActiveFilters = Object.values(initialFilters).some(category => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            return category.some(filter => filter.checked);
        });
        if (hasActiveFilters) {
            setTriggerFetch(true);
        }
    }, []); // This effect runs once on component mount

    useEffect(() => {
        // Fetch data only if the initial data load is needed or parameters truly change

        const serializedFilters = JSON.stringify(Object.fromEntries(
            Object.entries(currentFilters)// eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                .map(([key, options]) => [key, options.filter(option => option.checked).map(option => option.value)])
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .filter(([_, values]) => values.length > 0)
        ));

        const fetchUrl = GET_DATE_IDEAS_IDS(String(currentPage), String(pageSize), currentSort, serializedFilters);
        fetch(fetchUrl)
            .then(response => response.json())
            .then(data => {
                setDateIdeasIds(prevIdeas => [
                    ...prevIdeas,
                    ...data.filter((dataItem: {
                        id: number;
                    }) => !prevIdeas.some(prevItem => prevItem.id === dataItem.id))
                ]);
            })
            .catch(error => console.error('Error:', error));
    }, [triggerFetch, currentPage, pageSize, currentSort, currentFilters]);


    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setCurrentPage((prevPageNumber) => prevPageNumber + 1);
                }
            },
            {threshold: 0.5}  // Trigger the callback when the element is fully visible
        );

        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => {
            if (loadMoreRef.current) {
                observer.unobserve(loadMoreRef.current);
            }
        };
    }, []);

    const currentSortLabel = sortOptions.find(option => option.sortParam === currentSort)?.name || 'Sort';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const activeFilterCount = Object.values(currentFilters).flat().filter(f => f.checked).length;
    const handleClearFilters = () => {
        const resetFilters = JSON.parse(JSON.stringify(filters));  // Deep copy to reset
        setStagedFilters(resetFilters); // Reset staged filters as well
        setCurrentFilters(resetFilters);
        setCurrentSort(sortOptions[0].sortParam);  // Reset to default sort
        setTriggerFetch(true);  // Optionally trigger a fetch to update the displayed results
    };

    const toggleFilter = (category: string, value: string) => {
        const newFilters = {...stagedFilters};
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const categoryFilters = newFilters[category];
        const filterIndex = categoryFilters.findIndex((f: { value: string; }) => f.value === value);
        if (filterIndex > -1) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            newFilters[category][filterIndex].checked = !newFilters[category][filterIndex].checked;
        }
        setStagedFilters(newFilters);
    };

    const handleApplyFilters = () => {
        setCurrentFilters(stagedFilters);
        setTriggerFetch(true);  // Trigger data fetching
    };

    // @ts-ignore
    return (
        <div className="bg-white">
            <main className="pb-24">
                <div className="relative overflow-hidden bg-white pb-16">
                    {/* Decorative background image and gradient */}
                    <div aria-hidden="true" className="absolute inset-0">
                        <div className="absolute inset-0 mx-auto max-w-7xl overflow-hidden xl:px-8">
                            <img
                                src="https://cdn.pixabay.com/photo/2016/11/29/09/56/couple-1868866_1280.jpg"
                                alt=""
                                className="h-full w-full object-cover object-top"
                            />
                        </div>
                        <div className="absolute inset-0 bg-white bg-opacity-25"/>
                        <div className="absolute inset-0 bg-gradient-to-t from-white via-white"/>
                    </div>

                    {/* Callout */}
                    <section
                        aria-labelledby="sale-heading"
                        className="relative mx-auto flex max-w-7xl flex-col items-center px-4 pt-32 text-center sm:px-6 lg:px-8"
                    >
                        <div className="mx-auto max-w-2xl lg:max-w-none">
                            <h1 id="sale-heading"
                                className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
                                We're changing the way people connect.
                            </h1>
                            <p className="mx-auto mt-4 max-w-xl text-xl text-gray-600">
                                Discover unique date ideas and experiences that will help you create unforgettable
                                memories.
                            </p>
                        </div>
                    </section>


                </div>

                {/* Filters */}
                <Disclosure
                    as="section"
                    aria-labelledby="filter-heading"
                    className="grid items-center border-b border-t border-gray-200"
                >
                    <h2 id="filter-heading" className="sr-only">
                        Filters
                    </h2>
                    <div className="relative col-start-1 row-start-1 py-4">
                        <div
                            className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
                            <div>
                                <Disclosure.Button className="group flex items-center font-medium text-gray-700">
                                    <FunnelIcon
                                        className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    {activeFilterCount} Filter(s)
                                </Disclosure.Button>
                            </div>
                            <div className="pl-6">
                                <button type="button" className="text-gray-500" onClick={handleClearFilters}>
                                    Clear all
                                </button>
                            </div>
                        </div>
                    </div>
                    <Disclosure.Panel className="border-t border-gray-200 py-10">
                        <div
                            className="mx-auto grid max-w-7xl grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
                            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
                                <fieldset>
                                    <legend className="block font-medium">Budget</legend>
                                    <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                        {filters.budget.map((option, optionIdx) => (
                                            <div key={option.value} className="flex items-center text-base sm:text-sm">
                                                <input
                                                    id={`budget-${optionIdx}`}
                                                    name="budget[]"
                                                    defaultValue={option.value}
                                                    type="checkbox"
                                                    className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-red-600 focus:ring-red-500"
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-expect-error
                                                    checked={currentFilters.budget[optionIdx].checked}
                                                    onChange={() => toggleFilter('budget', option.value)}/>
                                                <label htmlFor={`budget-${optionIdx}`}
                                                       className="ml-3 min-w-0 flex-1 text-gray-600">
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend className="block font-medium">Type of Experience</legend>
                                    <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                        {filters.typeOfExperience.map((option, optionIdx) => (
                                            <div key={option.value} className="flex items-center text-base sm:text-sm">
                                                <input
                                                    id={`typeOfExperience-${optionIdx}`}
                                                    name="typeOfExperience[]"
                                                    defaultValue={option.value}
                                                    type="checkbox"
                                                    className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-red-600 focus:ring-red-500"
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-expect-error
                                                    checked={currentFilters.typeOfExperience[optionIdx].checked}
                                                    onChange={() => toggleFilter('typeOfExperience', option.value)}
                                                />
                                                <label htmlFor={`typeOfExperience-${optionIdx}`}
                                                       className="ml-3 min-w-0 flex-1 text-gray-600">
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </fieldset>
                            </div>
                            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
                                <fieldset>
                                    <legend className="block font-medium">Time Of Day</legend>
                                    <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                        {filters.timeOfDay.map((option, optionIdx) => (
                                            <div key={option.value} className="flex items-center text-base sm:text-sm">
                                                <input
                                                    id={`timeOfDay-${optionIdx}`}
                                                    name="timeOfDay[]"
                                                    defaultValue={option.value}
                                                    type="checkbox"
                                                    className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-red-600 focus:ring-red-500"
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-expect-error
                                                    checked={currentFilters.timeOfDay[optionIdx].checked}
                                                    onChange={() => toggleFilter('timeOfDay', option.value)}
                                                />
                                                <label htmlFor={`timeOfDay-${optionIdx}`}
                                                       className="ml-3 min-w-0 flex-1 text-gray-600">
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend className="block font-medium">Occasion</legend>
                                    <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                        {filters.occasion.map((option, optionIdx) => (
                                            <div key={option.value} className="flex items-center text-base sm:text-sm">
                                                <input
                                                    id={`occasion-${optionIdx}`}
                                                    name="occasion[]"
                                                    defaultValue={option.value}
                                                    type="checkbox"
                                                    className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-red-600 focus:ring-red-500"
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-expect-error
                                                    checked={currentFilters.occasion[optionIdx].checked}
                                                    onChange={() => toggleFilter('occasion', option.value)}
                                                />
                                                <label htmlFor={`occasion-${optionIdx}`}
                                                       className="ml-3 min-w-0 flex-1 text-gray-600">
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>

                                </fieldset>
                            </div>

                        </div>
                        <div className="pt-8 float-right pe-16">
                            <button
                                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-500"
                                onClick={handleApplyFilters}

                            >
                                Apply Filters
                            </button>
                        </div>

                    </Disclosure.Panel>
                    <div className="col-start-1 row-start-1 py-4">
                        <div className="mx-auto flex max-w-7xl justify-end px-4 sm:px-6 lg:px-8">
                            <Menu as="div" className="relative inline-block">
                                <div className="flex">
                                    <Menu.Button
                                        className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                        Sort: {currentSortLabel}
                                        <ChevronDownIcon
                                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {sortOptions.map((option) => (
                                                <Menu.Item key={option.name}>
                                                    {({active}) => (
                                                        <button
                                                            onClick={() => setCurrentSort(option.sortParam)}
                                                            className={classNames(
                                                                currentSort === option.sortParam ? 'font-medium text-gray-900' : 'text-gray-500',
                                                                active ? 'bg-gray-100' : '',
                                                                'block w-full text-left px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            {option.name}
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </Disclosure>

                {/* Product grid */}
                <section aria-labelledby="products-heading"
                         className="mx-auto max-w-7xl overflow-hidden sm:px-6 lg:px-8">
                    <h2 id="products-heading" className="sr-only">
                        Products
                    </h2>

                    <ul role="list"
                        className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-4 p-4 sm:p-0">                        {dateIdeasIds.map((idea) => (
                        <DateIdea dateId={idea.id} key={idea.id}/>
                    ))}
                    </ul>
                    <div ref={loadMoreRef}/>
                </section>

            </main>

        </div>
    )
}
