import {Component, Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {GET_DATE_IDEA_BY_ID, TRACK_CLICK} from "../endpoints.tsx";

interface DateIdeaProps {
    dateId: number;
}


const tabs = [
    {name: 'Description', href: '#', current: true},
    {name: 'Key Features', href: '#', current: false},
    {name: 'Preparation Tips', href: '#', current: false},
    {name: 'FAQ', href: '#', current: false},
    {name: 'Specifics', href: '#', current: false},
]


class DescriptionContent extends Component<{ dateIdea: DateIdea | null }> {
    render() {
        return (
            <div className="bg-white">
                <div className="px-6 py-12 sm:px-6 sm:py-6 lg:px-8">
                    <div className="mx-auto max-w-2xl">
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600 prose"
                           dangerouslySetInnerHTML={{__html: this.props.dateIdea?.description || ""}}>
                        </p>

                    </div>
                </div>
            </div>
        )
    }
}

class KeyFeaturesContent extends Component<{ dateIdea: DateIdea | null }> {
    render() {
        return (
            <div className="bg-white">
                <div className="px-6 py-12 sm:px-6 sm:py-12 lg:px-8">
                    <div className="mx-auto max-w-2xl">
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600 prose"
                           dangerouslySetInnerHTML={{__html: this.props.dateIdea?.key_features || ""}}>
                        </p>

                    </div>
                </div>
            </div>
        )
    }
}

class PreparationTipsContent extends Component<{ dateIdea: DateIdea | null }> {
    render() {
        return (
            <div className="bg-white">
                <div className="px-6 py-12 sm:px-6 sm:py-12 lg:px-8">
                    <div className="mx-auto max-w-2xl">
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600 prose"
                           dangerouslySetInnerHTML={{__html: this.props.dateIdea?.preparation_tips || ""}}>
                        </p>

                    </div>
                </div>
            </div>
        )
    }
}

class FAQContent extends Component<{ dateIdea: DateIdea | null }> {
    render() {
        return (
            <div className="bg-white">
                <div className="px-6 py-12 sm:px-6 sm:py-12 lg:px-8">
                    <div className="mx-auto max-w-2xl">
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600 prose"
                           dangerouslySetInnerHTML={{__html: this.props.dateIdea?.FAQ || ""}}>
                        </p>

                    </div>
                </div>
            </div>
        )
    }
}

class SpecificsContent extends Component<{ dateIdea: DateIdea | null }> {
    render() {
        return (
            <div className="bg-white ">
                <div className="px-6 py-12 sm:px-6 sm:py-12 lg:px-8">
                    <div className="mx-auto max-w-2xl">
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                            <span className="font-bold">Type:</span> {this.props.dateIdea?.type}
                        </p>
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                            <span className="font-bold">Budget:</span> {this.props.dateIdea?.budget_range}
                        </p>
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                            <span className="font-bold">Rating:</span> {this.props.dateIdea?.rating}
                        </p>
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                            <span className="font-bold">Review Count:</span> {this.props.dateIdea?.review_count}
                        </p>
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                            <span className="font-bold">Time of Day:</span> {this.props.dateIdea?.time_of_day}
                        </p>
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                            <span className="font-bold">Duration:</span> {this.props.dateIdea?.duration}
                        </p>
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                            <span className="font-bold">Occasion:</span> {this.props.dateIdea?.occasion}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default function DateIdea({dateId}: DateIdeaProps) {
    const [dateIdea, setDateIdea] = useState<DateIdea | null>(null);
    const [isHovered, setIsHovered] = useState(false);
    const [baseUrl, setBaseUrl] = useState('');
    // modal stuff
    const [isOpen, setIsOpen] = useState(false);

    // tabs inside the modal
    const [selectedTab, setSelectedTab] = useState(tabs[0].name);

    function createSlug(title: string | undefined) {
        if (!title) return '';
        return title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '');
    }

    const openModal = () => {
        setIsOpen(true);
        fetch(TRACK_CLICK(dateId)).then();

        // Save the current base URL only if it hasn't been saved yet
        if (!baseUrl) setBaseUrl(window.location.href);

        const slug = createSlug(dateIdea?.name || '');
        const newUrl = `${window.location.origin}/date-idea/${dateId}/${slug}`;
        window.history.pushState({path: newUrl}, '', newUrl);
    };

    const closeModal = () => {
        setIsOpen(false);

        // Revert URL change when the modal is closed
        window.history.pushState({path: baseUrl}, '', baseUrl);

        // Clear the saved base URL
        setBaseUrl('');
    };

    useEffect(() => {
        let retryCount = 0;
        const maxRetries = 10;
        const fetchData = () => {
            fetch(GET_DATE_IDEA_BY_ID(dateId))
                .then(response => response.json())
                .then(data => {
                    setDateIdea(data);
                    if (data.is_updating && retryCount < maxRetries) {
                        retryCount++;
                        setTimeout(fetchData, retryCount * 1000); // Wait for retryCount seconds before retrying
                    }
                })
                .catch(error => console.error('Error:', error));
        };

        fetchData();
    }, [dateId]);


    function getBudgetSymbol(budget: string | undefined) {
        switch (budget) {
            case 'Low':
                return '💰';
            case 'Moderate':
                return '💰💰';
            case 'High':
                return '💰💰💰';
            default:
                return '~';
        }
    }

    function getTimeOfDaySymbol(timeOfDay: string | undefined) {
        switch (timeOfDay) {
            case 'Morning':
                return '🌅'; // Sunrise emoji
            case 'Afternoon':
                return '☀️'; // Sun emoji
            case 'Evening':
                return '🌆'; // Cityscape at dusk emoji
            case 'Late Night':
                return '🌙'; // Crescent moon emoji
            default:
                return '~';
        }
    }

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }


    return (
        <li key={dateIdea?.id} className="relative">
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-red-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img src={dateIdea?.image_src_medium} alt={dateIdea?.image_search_term}
                     className={`pointer-events-none object-cover `}
                     style={{filter: isHovered ? 'brightness(50%)' : 'brightness(75%)'}}
                />
                <div className={`text-2xl absolute inset-0 text-white `}>
                    <div className={`absolute inset-0 text-white flex items-center justify-center `}>

                        {/*area for stuff to show up on NOT hover*/}
                        {!isHovered && (
                            <div className="text-center">
                                <h1 className="w-full text-sm font-bold tracking-tight text-white sm:text-md md:text-lg lg:text-xl"
                                    style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                                    {dateIdea?.name}
                                </h1>
                                <p className="w-full mt-2 text-sm leading-8 text-gray-300 sm:text-base md:text-lg lg:text-xl"
                                   style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                                    {dateIdea?.theme_and_ambience}
                                </p>
                            </div>)}

                        {!isHovered && (
                            <span
                                className={`absolute bottom-1.5 right-1.5 text-sm sm:text-base md:text-lg lg:text-xl text-shadow`}
                                style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>{getBudgetSymbol(dateIdea?.budget_range)}</span>

                        )}
                        {!isHovered && (
                            <span
                                className={`absolute bottom-1.5 left-1.5 text-sm sm:text-base md:text-lg lg:text-xl text-shadow`}
                                style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}
                            >
    {getTimeOfDaySymbol(dateIdea?.time_of_day)}
</span>
                        )}
                    </div>

                    {/*area for stuff to show up on hover*/}
                    {isHovered && (
                        <div className={`absolute inset-0  flex items-center`}>
                            <ul className="list-outside text-base ms-4 text-gray-300 space-y-2 ">
                                <li
                                    style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                                    👗 Dress Code: {dateIdea?.dress_code}
                                </li>
                                <li
                                    style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                                    🎟️ Occasion: {dateIdea?.occasion}
                                </li>
                                <li
                                    style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                                    ⏱️ Duration: {dateIdea?.duration}
                                </li>
                                <li
                                    style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                                    🔀 Type: {dateIdea?.type}
                                </li>
                            </ul>
                        </div>
                    )}

                </div>
                <button type="button" className="absolute inset-0 focus:outline-none" onClick={openModal}>
                    <span className="sr-only">View details for {dateIdea?.name}</span>
                </button>
                <Transition.Root show={isOpen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 z-50 flex items-center justify-center"
                            onClose={closeModal}>
                        <div className="fixed inset-0 bg-black opacity-50" aria-hidden="true"></div>
                        {/* This is the backdrop */}

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                                <div>
                                    <div className="relative overflow-hidden">
                                        <div aria-hidden="true" className="absolute inset-0">
                                            <div className="absolute inset-0 mx-auto max-w-7xl overflow-hidden ">
                                                <img
                                                    src={dateIdea?.image_src_large}
                                                    alt={dateIdea?.image_search_term}
                                                    className="h-full w-full object-cover object-center"/>
                                            </div>
                                            <div className="absolute inset-0 bg-white bg-opacity-0"></div>
                                            <div className="absolute inset-0"
                                                 style={{background: "linear-gradient(to bottom, transparent, white 99%, white)"}}></div>
                                        </div>
                                        <section aria-labelledby="sale-heading"
                                                 className="relative mx-auto max-w-7xl flex-col items-center px-4 pt-32 text-center sm:px-6 lg:px-8">
                                            <div className="mx-auto max-w-2xl lg:max-w-none ">
                                                <h1 id="sale-heading"
                                                    className=" -mt-24 text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl "
                                                    style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                                                    {dateIdea?.name}
                                                </h1><p
                                                className="mx-auto mt-4 max-w-xl text-xl text-white"
                                                style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>{dateIdea?.theme_and_ambience}</p>
                                            </div>
                                        </section>
                                    </div>
                                    <div className="text-center relative">
                                        <div>
                                            <div className="sm:hidden">
                                                <label htmlFor="tabs" className="sr-only">
                                                    Select a tab
                                                </label>
                                                <select
                                                    id="tabs"
                                                    name="tabs"
                                                    className="block w-full rounded-md border-gray-300 focus:border-red-500 focus:ring-red-500"
                                                    value={selectedTab}
                                                    onChange={(e) => setSelectedTab(e.target.value)}
                                                >
                                                    {tabs.map((tab) => (
                                                        <option key={tab.name}>{tab.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="hidden sm:block">
                                                <div className="border-b border-gray-200">
                                                    <nav className="-mb-px flex" aria-label="Tabs">
                                                        {tabs.map((tab) => (
                                                            <button
                                                                key={tab.name}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSelectedTab(tab.name);
                                                                    tabs.forEach(t => t.current = false);
                                                                    tab.current = true;
                                                                }}
                                                                className={classNames(
                                                                    selectedTab === tab.name
                                                                        ? 'border-red-500 text-red-600'
                                                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                                    'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'
                                                                )}
                                                                aria-current={selectedTab === tab.name ? 'page' : undefined}
                                                            >
                                                                {tab.name}
                                                            </button>
                                                        ))}
                                                    </nav>
                                                </div>
                                            </div>
                                            <div className="h-[400px] overflow-auto text-left">
                                                {selectedTab === 'Description' &&
                                                    <DescriptionContent dateIdea={dateIdea}/>}
                                                {selectedTab === 'Key Features' &&
                                                    <KeyFeaturesContent dateIdea={dateIdea}/>}
                                                {selectedTab === 'Preperation Tips' &&
                                                    <PreparationTipsContent dateIdea={dateIdea}/>}
                                                {selectedTab === 'FAQ' && <FAQContent dateIdea={dateIdea}/>}
                                                {selectedTab === 'Specifics' && <SpecificsContent dateIdea={dateIdea}/>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </Dialog>
                </Transition.Root>
            </div>
        </li>
    )
}
