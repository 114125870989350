import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import NotFoundPage from './pages/NotFoundPage';
import BlogHome from "./pages/BlogHome.tsx";
import DateIdeaPage from "./pages/DateIdeaPage.tsx";
import {Helmet} from 'react-helmet';
import BlogPage from "./pages/BlogPage.tsx";
import BlogCategories from "./pages/BlogCategories.tsx";
import BlogArchives from "./pages/BlogArchives.tsx";

function App() {
    return (
        <Router>
            <Helmet>
                <title>AIDateNightPlanner - The Worlds Best Date Night Planner</title>
                <meta name="description"
                      content="Harness The Power Of Artificial Intelligence With AIDateNightPlanner.com · Date Night Ideas · Find Your Next Date Night Here."/>
            </Helmet>
            <Navbar/>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="blog" element={<BlogHome/>}/>
                <Route path="blog_post/:slug" element={<BlogPage/>}/>
                <Route path="blog/category/:title" element={<BlogCategories/>}/>
                <Route path="blog/archive/:year/:month" element={<BlogArchives/>}/>
                <Route path="about" element={<AboutPage/>}/>
                <Route path="/date-idea/:id/:title" element={<DateIdeaPage/>}/>
                <Route path="*" element={<NotFoundPage/>}/>
            </Routes>
        </Router>
    );
}

export default App;
