import {useEffect, useState} from "react";
import {GET_BLOG_HOMEPAGE} from "../endpoints.tsx";
import {InformationCircleIcon} from "@heroicons/react/16/solid";
import {Helmet} from "react-helmet";


const BlogHome = () => {
    const [featuredPosts, setFeaturedPosts] = useState<FeaturedPost[]>([]);
    const [latestArticle, setLatestArticle] = useState<FeaturedPost>();
    const [archives, setArchives] = useState<Archive[]>([]);
    const [categories, setcategories] = useState<Category[]>([]);

    const formatDate = (dateString: string) => {
        const options = {year: 'numeric', month: 'long', day: 'numeric'};
        // @ts-ignore
        return new Date(dateString).toLocaleDateString(undefined, options);
    }
    useEffect(() => {

        const fetchData = () => {
            fetch(GET_BLOG_HOMEPAGE())
                .then(response => response.json())
                .then(data => {
                    setFeaturedPosts(data.short_features);
                    setLatestArticle(data.latest_article);
                    setArchives(data.archives);
                    setcategories(data.categories);
                })
                .catch(error => console.error('Error:', error));
        };

        fetchData();
    }, []);

    // @ts-ignore
    return (
        <div className="bg-white pb-24 sm:pb-32 pt-12">
            <Helmet>
                <title>Blog · AIDateNightPlanner</title>
                <meta name="description"
                      content="Explore AIDateNightPlanner's blog for insightful articles, tips and advice on dating and relationships. Discover how to plan the perfect date night and improve your dating life."/>
            </Helmet>
            <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12 border-b">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">From the blog</h2>
                    <p className="mt-2 text-lg leading-8 text-gray-600">
                        Learn how to improve your dating life with our blog posts.
                    </p>
                </div>
                <div
                    className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {featuredPosts.map((post) => (
                        <article key={post.id} className="flex flex-col items-start justify-between">
                            <div className="relative w-full">
                                <img
                                    src={post.img_src_large}
                                    alt=""
                                    className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                />
                                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"/>
                            </div>
                            <div className="max-w-xl">
                                <div className="mt-8 flex items-center gap-x-4 text-xs">
                                    <time dateTime={post.date_posted} className="text-gray-500">
                                        {formatDate(post.date_posted)}
                                    </time>
                                    <a
                                        href={`blog/category/${post.category}`}
                                        className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                                    >
                                        {post.category}
                                    </a>
                                </div>
                                <div className="group relative">
                                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                        <a href={`blog_post/${post.slug}`}>
                                            <span className="absolute inset-0"/>
                                            {post.title}
                                        </a>
                                    </h3>
                                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.meta_description}</p>
                                </div>

                            </div>
                        </article>
                    ))}
                </div>
            </div>

            <div className="mx-auto max-w-7xl px-6 lg:px-8 flex flex-col sm:flex-row pt-12">
                <div className="mx-auto w-full sm:w-3/4">
                    <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                        <div key={latestArticle?.id}>
                            <p className="text-base font-semibold leading-7 text-indigo-600">{latestArticle?.category}</p>
                            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{latestArticle?.title}</h1>

                            <figure className="mt-16">
                                <img
                                    className="aspect-video rounded-xl bg-gray-50 object-cover"
                                    src={latestArticle?.img_src_large}
                                    alt={latestArticle?.img_search_term}
                                />
                                <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
                                    <InformationCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-300"
                                                           aria-hidden="true"/>
                                    {latestArticle?.img_search_term}
                                </figcaption>
                            </figure>
                            <p className="mt-6 text-xl leading-8">
                                {latestArticle?.meta_description}
                            </p>
                            <div className="mt-10 max-w-2xl ">
                                <div className={"prose"}
                                     dangerouslySetInnerHTML={{__html: latestArticle?.content || ""}}>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="w-full sm:w-1/4 mt-6 sm:mt-0 ">

                    <div className="card bg-white p-6 rounded-lg shadow prose">
                        <h2 className="text-2xl font-bold mb-2">About</h2>
                        <p>AIdateNightPlanner is a platform that provides advice on dating and relationships. We
                            offer a variety of articles and resources to help you plan the perfect date night. </p>
                    </div>

                    <div className="card bg-white p-6 rounded-lg shadow mt-6">
                        <h2 className="text-2xl font-bold mb-2">Categories</h2>
                        <ul className={"prose"}>
                            {categories.map((category) => (
                                <li key={category}><a href={`/blog/category/${category}`}>{category}</a></li>
                            ))}
                        </ul>
                    </div>

                    <div className="card bg-white p-6 rounded-lg shadow mt-6">
                        <h2 className="text-2xl font-bold mb-2">Archives</h2>
                        <ul className={"prose"}>
                            {archives.map((archive) => (
                                <li key={`${archive.year}-${archive.month}`}><a
                                    href={`/blog/archive/${archive.year}/${archive.month}`}>{archive.text}</a></li>
                            ))}
                        </ul>
                    </div>
                </div>

            </div>

        </div>


    )
}

export default BlogHome