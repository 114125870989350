import {useEffect, useState} from "react";
import {GET_BLOG_POST} from "../endpoints.tsx";
import {InformationCircleIcon} from "@heroicons/react/16/solid";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {stripHtml} from "../utils.tsx";

const BlogPage = () => {
    const {slug} = useParams();
    const [post, setPost] = useState<FeaturedPost>();
    const [archives, setArchives] = useState<Archive[]>([]);
    const [categories, setcategories] = useState<Category[]>([]);


    useEffect(() => {

        const fetchData = () => {
            // @ts-ignore
            fetch(GET_BLOG_POST(slug))
                .then(response => response.json())
                .then(data => {
                    setPost(data.blog_post);
                    setArchives(data.archives);
                    setcategories(data.categories);
                })
                .catch(error => console.error('Error:', error));
        };

        fetchData();
    }, []);

    return (
        <div className="mx-auto max-w-7xl px-6 lg:px-8 flex flex-col sm:flex-row pt-12">
            <Helmet>
                <title>{post?.title ? `${post.title} - AIDateNightPlanner` : 'AIDateNightPlanner'}</title>
                <meta name="description"
                      content={post?.meta_description ? `Discover the perfect date night · ${stripHtml(post.meta_description)}` : 'Discover the perfect date night'}/>
            </Helmet>
            <div className="mx-auto w-full sm:w-3/4">
                <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    <div key={post?.id}>
                        <a href={`/blog/category/${post?.category}`}><p
                            className="text-base font-semibold leading-7 text-indigo-600">{post?.category}</p>
                        </a>
                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{post?.title}</h1>

                        <figure className="mt-16">
                            <img
                                className="aspect-video rounded-xl bg-gray-50 object-cover"
                                src={post?.img_src_large}
                                alt={post?.img_search_term}
                            />
                            <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
                                <InformationCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-300"
                                                       aria-hidden="true"/>
                                {post?.img_search_term}
                            </figcaption>
                        </figure>
                        <p className="mt-6 text-xl leading-8">
                            {post?.meta_description}
                        </p>
                        <div className="mt-10 max-w-2xl ">
                            <div className={"prose"}
                                 dangerouslySetInnerHTML={{__html: post?.content || ""}}>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="w-full sm:w-1/4 mt-6 sm:mt-0 ">

                <div className="card bg-white p-6 rounded-lg shadow prose">
                    <h2 className="text-2xl font-bold mb-2">About</h2>
                    <p>AIdateNightPlanner is a platform that provides advice on dating and relationships. We
                        offer a variety of articles and resources to help you plan the perfect date night. </p>
                </div>

                <div className="card bg-white p-6 rounded-lg shadow mt-6">
                    <h2 className="text-2xl font-bold mb-2">Categories</h2>
                    <ul className={"prose"}>
                        {categories.map((category) => (
                            <li key={category}><a href={`/blog/category/${category}`}>{category}</a></li>
                        ))}
                    </ul>
                </div>

                <div className="card bg-white p-6 rounded-lg shadow mt-6">
                    <h2 className="text-2xl font-bold mb-2">Archives</h2>
                    <ul className={"prose"}>
                        {archives.map((archive) => (
                            <li key={`${archive.year}-${archive.month}`}><a
                                href={`/blog/archive/${archive.year}/${archive.month}`}>{archive.text}</a></li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default BlogPage;