export const LOGO_SRC = "/logo.png"

export const filters = {
    budget: [
        {value: 'Low', label: 'Low', checked: false},
        {value: 'Moderate', label: 'Moderate', checked: false},
        {value: 'High', label: 'High', checked: false},
    ],
    typeOfExperience: [
        {value: 'Dine Out', label: 'Dine Out', checked: false},
        {value: 'Outdoor Adventure', label: 'Outdoor Adventure', checked: false},
        {value: 'Cultural Experience', label: 'Cultural Experience', checked: false},
        {value: 'At Home', label: 'At Home', checked: false},
        {value: 'Virtual Date', label: 'Virtual Date', checked: false},
    ],
    timeOfDay: [
        {value: 'Morning', label: 'Morning', checked: false},
        {value: 'Afternoon', label: 'Afternoon', checked: false},
        {value: 'Evening', label: 'Evening', checked: false},
        {value: 'Late Night', label: 'Late Night', checked: false},
    ],
    // duration: [
    //     {value: 'Quick', label: 'Quick (under 1 hour)', checked: false},
    //     {value: 'Moderate', label: 'Moderate (1-3 hours)', checked: false},
    //     {value: 'Long', label: 'Long (over 3 hours)', checked: false},
    // ],
    occasion: [
        {value: 'First Date', label: 'First Date', checked: false},
        {value: 'Anniversary', label: 'Anniversary', checked: false},
        {value: 'Special Occasion', label: 'Special Occasion', checked: false},
        {value: 'Casual Date', label: 'Casual Date', checked: false},
        {value: 'Proposal', label: 'Proposal', checked: false},
    ],
}

export const sortOptions = [
    {name: 'Most Popular', sortParam: 'most_popular', current: true},
    {name: 'Best Rating', sortParam: 'best_rating', current: false},
    {name: 'Newest', sortParam: 'newest', current: false},
    {name: 'Budget Low to High', sortParam: 'budget_low_to_high', current: false},
    {name: 'Budget High to Low', sortParam: 'budget_high_to_low', current: false},
];