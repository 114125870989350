import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {GET_DATE_IDEA_BY_ID} from "../endpoints.tsx";
import {Helmet} from "react-helmet";
import {stripHtml} from "../utils.tsx";

const DateIdeaPage = () => {
    const {id} = useParams();

    const [dateIdea, setDateIdea] = useState<DateIdea | null>(null);

    useEffect(() => {
        let retryCount = 0;
        const maxRetries = 10;

        if (!id) {
            return;
        }
        const fetchData = () => {
            fetch(GET_DATE_IDEA_BY_ID(Number(id)))
                .then(response => response.json())
                .then(data => {
                    setDateIdea(data);
                    if (data.is_updating && retryCount < maxRetries) {
                        retryCount++;
                        setTimeout(fetchData, retryCount * 1000); // Wait for retryCount seconds before retrying
                    }
                })
                .catch(error => console.error('Error:', error));
        };

        fetchData();
    }, [id]);

    if (!dateIdea) {
        return <div>Loading...</div>;
    }

// Helper function to remove HTML tags


    return (
        <div className="bg-white mb-16 ">
            <Helmet>
                <title>{dateIdea.name} - AIDateNightPlanner</title>
                <meta name="description"
                      content={`Discover the perfect date night idea · ${stripHtml(dateIdea.description)}`}/>
            </Helmet>
            <main className="isolate">
                {/* Image section */}
                <div className="my-12 sm:my-12 xl:mx-auto xl:max-w-7xl xl:px-8">
                    <img
                        src={dateIdea.image_src_large}
                        alt={dateIdea?.image_search_term}
                        className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
                    />
                </div>

                {/* Values section */}
                <div className="mx-auto mt-12 max-w-7xl px-6 sm:mt-12 lg:px-8">
                    <div className="mx-auto lg:mx-0">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{dateIdea.name}</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600 prose"
                           dangerouslySetInnerHTML={{__html: dateIdea?.description || ""}}>
                        </p>
                    </div>
                    <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        <div className={"prose"}>
                            <dt className="font-semibold text-gray-900">FAQ</dt>
                            <dd className="mt-1 text-gray-600"
                                dangerouslySetInnerHTML={{__html: dateIdea?.FAQ || ""}}></dd>
                        </div>
                        <div className={"prose"}>
                            <dt className="font-semibold text-gray-900">Key Features</dt>
                            <dd className="mt-1 text-gray-600"
                                dangerouslySetInnerHTML={{__html: dateIdea?.key_features || ""}}></dd>
                        </div>
                        <div className={"prose"}>
                            <dt className="font-semibold text-gray-900">Preparation Tips</dt>
                            <dd className="mt-1 text-gray-600 "
                                dangerouslySetInnerHTML={{__html: dateIdea?.preparation_tips || ""}}></dd>
                        </div>
                        <div className={"prose"}>
                            <dt className="font-semibold text-gray-900">Theme & Ambience</dt>
                            <dd className="mt-1 text-gray-600">{dateIdea.theme_and_ambience}</dd>
                        </div>

                        <div>
                            <dt className="font-semibold text-gray-900">Dress Code</dt>
                            <dd className="mt-1 text-gray-600">{dateIdea.dress_code}</dd>
                        </div>
                        <div>
                            <dt className="font-semibold text-gray-900">Occasion</dt>
                            <dd className="mt-1 text-gray-600">{dateIdea.occasion}</dd>
                        </div>
                        <div>
                            <dt className="font-semibold text-gray-900">Duration</dt>
                            <dd className="mt-1 text-gray-600">{dateIdea.duration}</dd>
                        </div>
                        <div>
                            <dt className="font-semibold text-gray-900">Budget Range</dt>
                            <dd className="mt-1 text-gray-600">{dateIdea.budget_range}</dd>
                        </div>
                        <div>
                            <dt className="font-semibold text-gray-900">Time of Day</dt>
                            <dd className="mt-1 text-gray-600">{dateIdea.time_of_day}</dd>
                        </div>
                    </dl>
                </div>

                {/*    /!* Blog section *!/*/}
                {/*    <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">*/}
                {/*        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">*/}
                {/*            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">From the blog</h2>*/}
                {/*            <p className="mt-2 text-lg leading-8 text-gray-600">*/}
                {/*                Vel dolorem qui facilis soluta sint aspernatur totam cumque.*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*        <div*/}
                {/*            className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">*/}
                {/*            {blogPosts.map((post) => (*/}
                {/*                <article*/}
                {/*                    key={post.id}*/}
                {/*                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"*/}
                {/*                >*/}
                {/*                    <img src={post.imageUrl} alt=""*/}
                {/*                         className="absolute inset-0 -z-10 h-full w-full object-cover"/>*/}
                {/*                    <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40"/>*/}
                {/*                    <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10"/>*/}

                {/*                    <div*/}
                {/*                        className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">*/}
                {/*                        <time dateTime={post.datetime} className="mr-8">*/}
                {/*                            {post.date}*/}
                {/*                        </time>*/}
                {/*                        <div className="-ml-4 flex items-center gap-x-4">*/}
                {/*                            <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">*/}
                {/*                                <circle cx={1} cy={1} r={1}/>*/}
                {/*                            </svg>*/}
                {/*                            <div className="flex gap-x-2.5">*/}
                {/*                                <img src={post.author.imageUrl} alt=""*/}
                {/*                                     className="h-6 w-6 flex-none rounded-full bg-white/10"/>*/}
                {/*                                {post.author.name}*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <h3 className="mt-3 text-lg font-semibold leading-6 text-white">*/}
                {/*                        <a href={post.href}>*/}
                {/*                            <span className="absolute inset-0"/>*/}
                {/*                            {post.title}*/}
                {/*                        </a>*/}
                {/*                    </h3>*/}
                {/*                </article>*/}
                {/*            ))}*/}
                {/*        </div>*/}
                {/*    </div>*/}
            </main>
        </div>
    );
};


export default DateIdeaPage;